
/*=================================================================
  Our Team
  ==================================================================*/

  .team {
    padding: 100px 0;
    background: #F6F6F6;
    .team-member {
      background: $light;
      .member-content {
        padding:25px 0;
        h3 {
          font-size: 20px;
          margin: 0 0 5px;
          color:#333;
        }
        span {
          font-size: 14px;
          color:#555;
        }
        p {
          color: #777;
          margin-top: 10px;
          padding:0 15px;
        }
      }
    }
    .member-photo {
      overflow: hidden;
      position: relative;
      &:hover img {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
      }

      img {
        -webkit-transition: all 0.6s ease 0s;
        -moz-transition: all 0.6s ease 0s;
        -o-transition: all 0.6s ease 0s;
        transition: all 0.6s ease 0s;
      }
      .mask {
        background: rgba( $primary-color, .7 );
        position: absolute;
        width: 100%;
        bottom: 0;
        top: auto;
        opacity: 0;
        filter: alpha(opacity=0);
        left: 0;
        right: 0;
        cursor: pointer;
        padding: 0;
        height: 80px;
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        ul {
          text-align: center;
          position: relative;
          margin-top: 22px;
          li {
            display: inline-block;
            margin-right: 7px;
            a {
              border: 1px solid #ddd;
              border-radius: 4px;
              color: #fff;
              display: block;
              font-size: 20px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              width: 35px;
              &::hover {
                background-color: #6cb670;
                border: 1px solid transparent;
              }
            }
          }
        }
      }
      &:hover .mask {
        opacity: 1;
        filter: alpha(opacity=100);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
      }
    }
  }



