/*=================================================================
  Navigation
==================================================================*/
.navigation {
  transition: .3s all;

  .logo-default {
    display: none;
  }

  .navbar-light .navbar-nav {
    .nav-item {
      .nav-link {
        color: $light;
        font-size: 14px;
        line-height: 26px;
        padding: 20px 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: .2s ease-in-out 0s;

        &:hover,
        &:active,
        &:focus {
          background: none;
          color: $primary-color;
        }
      }

      &.active {
        .nav-link {
          color: $primary-color;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .nav-link {
      padding: 10px !important;
    }
  }

  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
  }

  .dropdown-menu {
    border: 0;
    border-radius: 0;
    top: 80%;
    left: 5px;
    display: block;
    visibility: hidden;
    transition: .3s ease;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    background: #000;
  }

  @media (max-width: 991px) {
    .dropdown-menu {
      display: none;
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
      transform-origin: unset;
      text-align: center;
    }
  }

  .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
  }

  @media (max-width: 991px) {
    .dropdown-menu.show {
      display: block;
    }
  }

  .dropdown-item {
    color: rgba($color: $light, $alpha: .8);
    transition: .2s ease;
    padding: 10px;

    &:hover {
      color: $light;
      background: transparent;
      padding-left: 12px;
    }
  }
}

@media (max-width: 991px) {
  .navigation {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    background: $light;

    .navbar-light .navbar-nav {
      .nav-item {
        .nav-link {
          color: $black;
        }
      }
    }

    .logo-white {
      display: none;
    }

    .logo-default {
      display: block;
    }

    .navbar-light .navbar-toggler {
      color: rgba(#000, .5);
      border-color: rgba(#000, .1);
    }
  }
}

.sticky-header {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  background: $light;

  .navbar-light .navbar-nav {
    .nav-item {
      .nav-link {
        color: $black;
      }
    }
  }

  .logo-white {
    display: none;
  }

  .logo-default {
    display: block;
  }
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
}

.navbar-brand h1 {
  margin: 0;
}